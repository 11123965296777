import {apiRequest} from "./API"

export default {
    // Submit MoveIn
    getAcountsForUser: async (uuid, token) => {
        let email = await apiRequest(`/find-all-user-child-accounts/${uuid}`, "GET" , "", token);
        return email;
    },

    verifyToken: async (token) => {
        let email = await apiRequest(`/verify-token`, "POST" , "", token);
        return email;
    }
}