import React, { Component, Suspense, useState } from 'react';
import Layout from './components/Layout/';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';

// Import Css
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';

// Include Routes 
import routes from './routes';
//Custom Components
import PrivateRoute from "./util/PrivateRoute";
//Contexts
import { AuthContext } from "./contexts/Auth.js";
import {MoveInProvider} from "./contexts/MoveIns";

const existingTokens = JSON.parse(localStorage.getItem("tokens"));

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {  
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      )
    }
  };
}

const App = ()=> {

  const [authTokens, setAuthTokens] = useState(existingTokens);
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }
  const Loader = () => {
    return (
      <div id="preloader">
          <div id="status">
              <div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
              </div>
          </div>
      </div>
    );
}

    return (
      <React.Fragment>
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>  
          <MoveInProvider>
            <Router>
              <Suspense fallback = {Loader()} >
                <Switch>
                {routes.map((route, idx) =>
                    route.isWithoutLayout ?
                      <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                    :
                      <Route path={route.path} exact component={withLayout(route.component)} key={idx} />
                )}
                </Switch>
              </Suspense>
            </Router>
          </MoveInProvider>
        </AuthContext.Provider>
      </React.Fragment>
    );
}

export default withRouter(App);