import {apiRequest} from "./API"

export default {
    // Submit MoveIn
    CheckEmail: async (body) => {
        let email = await apiRequest(`/customer-portal/emailInUse`, "POST", body);
        return email;
    },
    AccountCheckEmail: async (body) => {
        let email = await apiRequest(`/account-check-email-in-use`, "POST", body);
        return email;
    },
    SendNotification: async (body) => {
        let email = await apiRequest(`/notification`, "POST", body);
        return email;
    } 
}
// /account-check-email-in-use