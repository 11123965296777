
/**
 * API Request handler
 * @param url - api endpoint
 * @param method - http method
 * @param bodyParams - body parameters of request
 */

const apiRequest =  async (
    url,
    method,
    bodyParams,
    token,
  )  => {
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api${url}`, {
      method,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: bodyParams ? JSON.stringify(bodyParams) : undefined
    });
  return await response.json();
    }
    catch(err){
      console.log(err)
     throw new Error(err);
    }
  };


export {apiRequest}