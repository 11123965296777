import React, { createContext, useState, useContext } from 'react';
import MoveIns from "../API/MoveIns";
import Email from "../API/Email"
import User from "../API/User"
import Properties from "../API/Properties";
import Units from "../API/Units";
import Meter from "../API/Meter"

import _ from "lodash"


export const MoveInContext = createContext({
    properties:[],
    selectedProperty:{},
    units:[],
    selectedUnit:{},
    newLink: false,
    moveInForm:{meterRead: 0},
    submittedMoveIn:false,
    fetchProperties:()=>{},
    selectProperty:()=>{},
    fetchUnits: ()=>{},
    selectUnit: ()=>{},
    editMoveInForm: ()=>{},
    resetMoveInForm:()=>{},
    submitMoveIn: ()=>{},
    checkEmail: ()=>{},
    getAcountsForUser: ()=> {},
    verifyToken: ()=> {},
    closeSubmissionNotification:()=>{}
});

const { Provider } = MoveInContext;

const MoveInProvider = (props) => {
    const [state, setState] = useState({
        properties:[],
        selectedProperty:{},
        units:[],
        selectedUnit:{},
        moveInForm:{},
        newLink: false,
        submittedMoveIn:false,
        fetchProperties:()=>{},
        selectProperty:()=>{},
        fetchUnits: ()=>{},
        selectUnit: ()=>{},
        getAcountsForUser: ()=>{},
        verifyToken: ()=>{},
        editMoveInForm: ()=>{},
        resetMoveInForm:()=>{},
        submitMoveIn: ()=>{},
        checkEmail: ()=>{},
        closeSubmissionNotification: ()=>{}
    })

    const fetchProperties = async()=>{
        let newProperties = await Properties.getProperties();
        let defaultProperty = newProperties[0];
        let newUnits = await Units.getUnits(defaultProperty.id);
        let defaultUnit = newUnits[0];
        console.log("STATE FETCH PROPS", state)
        console.log("FETCHPROPS", defaultProperty)
        setState({...state, properties:[...newProperties]})
        
    }

    const fetchUnits = async(propId, property)=>{
        console.log("FETCH UNIT STATE", state)
        if(propId) {
            let newUnits = await Units.getUnits(propId)
            
            newUnits = _.filter(newUnits, unit => {
                return unit.commonAccount == 0;
            })

            newUnits = _.orderBy(newUnits, ['address1', 'address2'], ['asc','asc'] );

            setState({...state, selectedProperty:property, units:[...newUnits]})
        }
        else {
            setState({...state, selectedProperty:{}, units: {}})

        }
        console.log("fetch units state", state)
    }

    const selectProperty = async (propertyId)=>{
        console.log(propertyId)
        if(propertyId) {
            console.log("ARGUEMENT ENTERED", propertyId)
            let latestPropertySelection = state.properties.filter(property => property.id == propertyId)[0]
            setState({...state, units: {}})
            await fetchUnits(propertyId, latestPropertySelection)
        }
        else {
            setState({...state, selectedProperty:{},  units: {}})
            console.log(state);

        }

    }

    const selectUnit = async (unitUuid)=>{
        let latesUnitSelection = state.units.filter(unit => unit.uuid == unitUuid)[0]
        setState({...state, selectedUnit:latesUnitSelection})
        console.log("UNIT STATE", state)
    }

    const editMoveInForm = (formChange)=>{
        console.log("I AM WORKING", formChange)
        setState({...state, moveInForm:{...state.moveInForm, ...formChange}})
        console.log("I AM details", state)
    }
    
    const resetMoveInForm = ()=>{
        setState({...state,newLink: false,
            accountsFound: 0, moveInForm:{}})
    }

    const closeSubmissionNotification = ()=>{
        setState({...state, submittedMoveIn:false})
    }

    const submitMoveIn = async (newMoveIn)=>{
        try{
        let response = await MoveIns.SubmitMoveIn(newMoveIn)

            if(response.createdMoveIn) {
                setState({
                    ...state,
                    properties:[],
                    selectedProperty:{},
                    units:[],
                    newLink: false,
                    accountsFound: 0,
                    selectedUnit:{},
                        moveInForm:{},
                    submittedMoveIn: true
                })
    
            }
            return response;
        }
        catch(err){
            return err;
        }
    }

    const checkEmail = async (checkEmail)=>{
        if(checkEmail) {
            try{
                let response = await Email.AccountCheckEmail(checkEmail)
                    return response;
                }
                catch(err){
                    return err;
                }

        }
    }

    const verifyToken = async(token) => {
        if(token) {
            try{
                let response = await User.verifyToken(token)
                    return response;
                }
                catch(err){
                    return err;
                }

        }
    }

    const getAccountsForUser = async (uuid, token)=>{
        if(uuid) {
            try{
                let response = await User.getAcountsForUser(uuid, token)
                let accounts = response.accounts.items;

                let latest = accounts[accounts.length-1]

                let newProperties = await Properties.getProperties();
                let defaultProperty = newProperties[0];
                let newUnits = await Units.getUnits(defaultProperty.id);
                let defaultUnit = newUnits[0];
                console.log("STATE FETCH PROPS", state)
                console.log("FETCHPROPS", defaultProperty)

                    setState({
                            ...state,
                            newLink: true,
                            vEmail: latest.email,
                            properties:[...newProperties],
                            accountEmail: latest.email,
                            accountsFound: accounts.length,
                            moveInForm:{
                                firstName:latest.firstName,
                                lastName: latest.lastName,
                                email: latest.email,
                                phone: latest.phone,
                                userAccountExists: 1
                            },
                        })
                    return response;
                }
                catch(err){
                    return err;
                }

        }
    }

    const MoveInState ={
        ...state,
        editMoveInForm:editMoveInForm,
        resetMoveInForm:resetMoveInForm,
        submitMoveIn:submitMoveIn,
        checkEmail:checkEmail,
        getAcountsForUser:getAccountsForUser,
        verifyToken:verifyToken,
        fetchProperties: fetchProperties,
        selectProperty: selectProperty,
        fetchUnits: fetchUnits,
        selectUnit: selectUnit,
        closeSubmissionNotification:closeSubmissionNotification
    }
  
    return (
      <Provider value={MoveInState} {...props}/>
    )
  }
  
  const useMoveInContext = () => useContext(MoveInContext);
  
  export { MoveInProvider, useMoveInContext }