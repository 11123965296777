import {apiRequest} from "./API"

export default {
  // Gets all Properties
  getProperties: async () => {
    let Properties = await apiRequest("/property", "GET");
    Properties = Properties.filter(property => {return property.statusDescription == "Active"})
    return Properties;
    },
    getProperty: async (uuid) =>{
    let Property = await apiRequest("/property/"+uuid, "GET");
    return Property;
    }

};