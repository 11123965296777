import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../contexts/Auth.js";

function PrivateRoute({ component: Component, ...rest }) {
    const { authTokens } = useAuth();
    let auth;
    if(authTokens)
         auth = (authTokens.token && authTokens.refreshToken) ? true : false;
    
    return (
        <Route
          {...rest}
          render={props =>
            auth ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      );
    }
    
export default PrivateRoute;